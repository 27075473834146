import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FaSave } from "react-icons/fa";
import { ref, push, set } from "firebase/database";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { FIREBASE_DB, FIREBASE_STORAGE } from "../config/firebaseinit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoArrowBack } from "react-icons/io5";

const AddArticle = () => {
  const [editorContent, setEditorContent] = useState();
  const [editorContentChina, setEditorContentChina] = useState();
  const [editorContentEng, setEditorContentEng] = useState();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    // Fetch categories from wherever they are stored (replace this with your actual data source)
    const fetchedCategories = ["Perikanan"];
    setCategories(fetchedCategories);
  }, []);

  const navigate = useNavigate();

  const handleSave = async () => {
    const articlesRef = ref(FIREBASE_DB, "artikel");
    const newArticleRef = push(articlesRef);

    const title = document.getElementById("judulArtikel").value;
    const titleChina = document.getElementById("judulArtikel-china").value;
    const titleEng = document.getElementById("judulArtikel-eng").value;
    const description = document.getElementById("descArt").value;
    const descriptionChina = document.getElementById("descArt-china").value;
    const descriptionEng = document.getElementById("descArt-eng").value;
    const content = editorContent;
    const contentChina = editorContentChina;
    const contentEng = editorContentEng;

    // Mendapatkan tanggal saat ini
    const currentDate = new Date();
    const dateString = currentDate.toISOString(); // Ubah tanggal ke format ISO

    const articleData = {
      judul: title,
      judulChina: titleChina,
      judulEng: titleEng,
      deskripsi: description,
      deskripsiChina: descriptionChina,
      deskripsiEng: descriptionEng,
      kategori: selectedCategory,
      konten: content,
      kontenChina: contentChina,
      kontenEng: contentEng,
      tanggal: dateString,
    };

    const imageInput = document.getElementById("art-image");
    const imageFile = imageInput.files[0];

    if (
      !title ||
      !titleChina ||
      !titleEng ||
      !description ||
      !descriptionChina ||
      !descriptionEng ||
      !selectedCategory ||
      !imageFile ||
      !editorContent.trim() ||
      !editorContentChina.trim() ||
      !editorContentEng.trim()
    ) {
      toast.error("Please fill in all required fields");
      return;
    }

    if (imageFile) {
      const storageRefVar = storageRef(
        FIREBASE_STORAGE,
        `images/${imageFile.name}`
      );

      try {
        await uploadBytes(storageRefVar, imageFile);
        console.log("Gambar berhasil diunggah ke Firebase Storage");

        const imageUrl = await getDownloadURL(storageRefVar);
        articleData.gambar = imageUrl;
      } catch (error) {
        console.error("Error saat mengunggah gambar:", error.message);
        toast("Failed adding image to storage");
      }
    }

    try {
      await set(newArticleRef, articleData);
      console.log("Artikel berhasil disimpan ke database!");
      toast.success("Article successfully created");
      setTimeout(() => {
        navigate("/admin"); // Navigasi ke halaman admin
      }, 2000);
    } catch (error) {
      console.error("Error saat menyimpan artikel:", error.message);
      toast("Failed to add article");
    }
  };

  return (
    <section className="absolute  overflow-x-hidden w-full h-full scroll-auto z-30">
      <div className="absolute w-full h-full scroll-auto z-30">
        <div className="grid grid-cols-2 shadow-xl">
          <div className=" py-5 lg:pl-12">
            <img
              src="../assets/images/logo-fuyu.png"
              className="float-left w-14 h-14"
              alt="logo-fuyu.png"
            />
            <div>
              <h1 className="mt-3.5 font-semibold text-lg">
                FUYU Aquatic International
              </h1>
            </div>
          </div>
          <div className="">
            <Link to="/admin">
              <button
                className="p-5 absolute right-20 top-6 w-[130px] h-[30px] rounded-lg transition ease-in-out delay-80 hover:-translate-y-1 
              hover:scale-105 duration-100 border-2 hover:border-2"
              >
                <IoArrowBack className="float-left w-7 h-7 -mt-3.5 -ml-1" />
                <p className="text-center text-base -mt-3">Back</p>
              </button>
            </Link>
          </div>
        </div>
        <div className="h-full rounded-t-[50px]">
          <div className="p-16">
            <h1 className="lg:text-4xl md:text-2xl sm:text-xl font-semibold text-lg">
              Tambah Artikel Baru
            </h1>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <div>
              <form className="pt-10">
                <div className="lg:text-xl md:text-base sm:text-sm font-semibold grid grid-cols-2 md:grid-cols-12 gap-4 items-center">
                  <label className="col-span-2" for="judulArtikel">Judul</label>
                  <input
                    type="text"
                    required
                    placeholder="Ketik judul artikel..."
                    id="judulArtikel"
                    className="w-full col-span-2 md:col-span-10 border p-2 rounded-md focus:outline-none placeholder:text-sm placeholder:font-normal font-normal text-sm"
                  ></input>
                  <label className="col-span-2" for="judulArtikel-china">Judul Bahasa China</label>
                  <input
                    type="text"
                    required
                    placeholder="Ketik judul artikel dalam bahasa china"
                    id="judulArtikel-china"
                    className="w-full col-span-2 md:col-span-10 border p-2 rounded-md focus:outline-none placeholder:text-sm placeholder:font-normal font-normal text-sm"
                  ></input>
                  <label className="col-span-2" for="judulArtikel-eng">Judul Bahasa Inggris</label>
                  <input
                    type="text"
                    required
                    placeholder="Ketik judul artikel dalam bahasa inggris"
                    id="judulArtikel-eng"
                    className="w-full col-span-2 md:col-span-10 border p-2 rounded-md focus:outline-none placeholder:text-sm placeholder:font-normal font-normal text-sm"
                  ></input>
                  <label className="col-span-2" for="descArt">Deskripsi</label>
                  <textarea
                    type="text"
                    placeholder="Ketik deskripsi singkat.."
                    required
                    name="descArt"
                    id="descArt"
                    className="w-full col-span-2 md:col-span-10 border p-2 rounded-md focus:outline-none placeholder:text-sm placeholder:font-normal font-normal text-sm"
                  ></textarea>
                  <label className="col-span-2" for="descArt-china">Deskripsi Bahasa China</label>
                  <textarea
                    type="text"
                    placeholder="Ketik deskripsi singkat dalam bahasa china"
                    required
                    name="descArt-china"
                    id="descArt-china"
                    className="w-full col-span-2 md:col-span-10 border p-2 rounded-md focus:outline-none placeholder:text-sm placeholder:font-normal font-normal text-sm"
                  ></textarea>
                  <label className="col-span-2" for="descArt-eng">Deskripsi Bahasa Inggris</label>
                  <textarea
                    type="text"
                    placeholder="Ketik deskripsi singkat dalam bahasa inggris"
                    required
                    name="descArt-eng"
                    id="descArt-eng"
                    className="w-full col-span-2 md:col-span-10 border p-2 rounded-md focus:outline-none placeholder:text-sm placeholder:font-normal font-normal text-sm"
                  ></textarea>
                  <label className="col-span-2" for="kategori">Kategori</label>
                  <select
                    required
                    id="kategori"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className="w-full col-span-2 md:col-span-4 border p-2 rounded-md focus:outline-none placeholder:text-sm placeholder:font-normal font-normal text-sm"
                  >
                    <option disabled selected>
                      Pilih kategori...
                    </option>
                    {categories.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                  <label className="col-span-2 md:text-center" for="art-image">Gambar</label>
                  <div className="text-base font-normal">
                    <input
                      required
                      name="artImage"
                      id="art-image"
                      type="file"
                    />
                  </div>
                </div>
                <div className="flex flex-wrap mt-1">
                  <div className="lg:text-xl md:text-base sm:text-sm font-semibold mt-5 w-2/12">
                    Konten Artikel
                  </div>
                  <div className="mt-3 w-10/12">
                    <CKEditor
                      editor={ClassicEditor}
                      data={editorContent}
                      config={{ placeholder: 'Masukkan Isi Artikel' }}
                      onChange={(event, editor) => {
                        const content = editor.getData();
                        setEditorContent(content);
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap mt-1">
                  <div className="lg:text-xl md:text-base sm:text-sm font-semibold mt-5 w-2/12">
                    Konten Artikel Bahasa China
                  </div>
                  <div className="mt-3 w-10/12">
                    <CKEditor
                      editor={ClassicEditor}
                      data={editorContentChina}
                      config={{
                        placeholder: 'Masukkan Isi Artikel Dalam Bahasa China'
                      }}
                      onChange={(event, editor) => {
                        const content = editor.getData();
                        setEditorContentChina(content);
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap mt-1">
                  <div className="lg:text-xl md:text-base sm:text-sm font-semibold mt-5 w-2/12">
                    Konten Artikel Bahasa Inggris
                  </div>
                  <div className="mt-3 w-10/12">
                    <CKEditor
                      editor={ClassicEditor}
                      data={editorContentEng}
                      config={{
                        placeholder: 'Masukkan Isi Artikel Dalam Bahasa Inggris'
                      }}
                      onChange={(event, editor) => {
                        const content = editor.getData();
                        setEditorContentEng(content);
                      }}
                    />
                  </div>
                </div>
                <div className="text-right pt-6">
                  <button
                    type="button"
                    onClick={handleSave}
                    className="text-white py-3 px-5 bg-blue-700 hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center me-2"
                  >
                    <FaSave className="float-left w-6 h-6 -ml-2 mr-2" />
                    Simpan
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AddArticle;
