import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../components/header";
import GuestLayout from "../components/layouts/GuestLayout";

const Aboutus = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  const productData = [
    {
      label: t("Produk Kerapu Macan"),
      image: 'assets/images/products/kerapu-macan.jpeg',
    },
    {
      label: t("Produk Kerapu Bebek"),
      image: 'assets/images/products/kerapu-bebek.jpg',
    },
    {
      label: t("Produk Kerapu Batik"),
      image: 'assets/images/products/kerapu-batik.jpeg',
    },
    {
      label: t("Produk Kerapu Sunuk"),
      image: 'assets/images/products/kerapu-sunuk.jpeg',
    },
  ];

  return (
    <GuestLayout>
      <section>
        <Header
          title={t("Navbar Tentang Kami")}
          subtitle="FUYU Aquatic International"
          withBrand
        />

        <div className="grid grid-cols-1 md:grid-cols-2 mx-10 my-10 md:my-20 justify-items-stretch gap-x-5">
          <div className="justify-self-center" data-aos="zoom-in">
            <img
              src="assets/images/about.png"
              className="rounded-md w-[400px]"
              alt="img"
            />
          </div>
          <div className="flex items-center mt-3 md:mt-0">
            <div className="text-center md:text-start" data-aos="fade-left">
              <h2 className="text-primary-fuyu font-bold uppercase text-5xl mb-4">
                {t("Navbar Tentang Kami")}
              </h2>
              <p className="text-abu-gelap-fuyu md:w-4/5">
                {t("Home Deskripsi")}
              </p>
            </div>
          </div>
        </div>

        <h2 className="text-primary-fuyu font-bold uppercase text-2xl sm:text-2xl md:text-5xl text-center" data-aos="zoom-in">
          {t("Tentang Kami VM Perusahaan")}
        </h2>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-5 lg:gap-x-20 mt-14 mx-10 lg:mx-0">
          <div className="bg-blue-100 w-full rounded-r-lg p-10" data-aos="zoom-in">
            <h2 className="text-primary-fuyu font-bold uppercase text-4xl mb-4">
              {t("Tentang Kami Visi Kami")}
            </h2>
            <p className="text-abu-gelap-fuyu text-justify">{t("Tentang Kami Visi")}</p>
          </div>
          <div className="bg-blue-100 w-full rounded-l-lg p-10" data-aos="zoom-in">
            <h2 className="text-primary-fuyu font-bold uppercase text-4xl mb-4">
              {t("Tentang Kami Misi Kami")}
            </h2>
            <ul className="list-none text-abu-gelap-fuyu text-justify">
              <li>{t("Tentang Kami Misi 1")}</li>
              <li>{t("Tentang Kami Misi 2")}</li>
              <li>{t("Tentang Kami Misi 3")}</li>
            </ul>
          </div>
        </div>

        <div className="lg:px-16 md:px-12 px-8 mx-auto lg:pt-16 md:pt-16 pt-8">
          <p
            data-aos="zoom-in"
            className="text-left lg:text-center mx-auto font-fuyu font-semibold mt-3 text-2xl sm:text-2xl md:text-6xl lg:text-6xl text-primary-fuyu"
          >
            {t("Produk Judul")}
          </p>
          <div
            className={`p-10 bg-blue-service rounded-md mt-5 w-full xl:w-4/5 px-8 mx-auto mb-20`}
            data-aos="zoom-in"
          >
            <p className="mt-3 text-justify">{t("Desc Product lainnya")}</p>
            <div className="my-5">
              <p>{t("Product Subjudul")}</p>
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 mb-5">
              {productData.map((product, i) => (
                <div key={i} className="bg-white p-5 rounded-md" data-aos="zoom-in">
                  <div className="relative w-full h-[65%]">
                    <img
                      src={product.image}
                      className="rounded-md w-full h-full object-cover"
                      alt={`img-product${i}`}
                    />
                  </div>
                  <p className="font-medium text-xl lg:text-base mt-2">{product.label}</p>
                </div>
              ))}
            </div>
            <div className="bg-white p-5 rounded-md lg:flex lg:space-x-4" data-aos="zoom-in">
              <div className="flex justify-center">
                <div className="md:w-[70%] lg:w-full">
                  <img
                    src="assets/images/products/napoleon.png"
                    className="rounded-md mb-2 object-cover"
                    alt="img"
                  />
                </div>
              </div>
              <div className="mt-4 lg:mt-0">
                <p className="font-semibold">{t("Produk Humphead Wrasse")}</p>
                <p className="text-justify">{t("Desc Produk Humphead Wrasse")}</p>
              </div>
            </div>
            <p className="text-justify my-2">{t("Desc Produk")}</p>
            <div className="grid md:grid-cols-2 gap-4 mb-5">
              <div className="bg-white p-5 rounded-md" data-aos="zoom-in">
                <div className="relative w-full h-[15rem]">
                  <img
                    src="assets/images/products/lobster-mutiara.jpg"
                    className="rounded-md w-full h-full object-cover"
                    alt="img-product"
                  />
                </div>
                <p className="font-medium text-xl lg:text-base mt-2">{t("Produk Lobster Mutiara")}</p>
              </div>
              <div className="bg-white p-5 rounded-md" data-aos="zoom-in">
                <div className="relative w-full h-[15rem]">
                  <img
                    src="assets/images/products/kepiting-lumpur.jpg"
                    className="rounded-md w-full h-full object-cover"
                    alt="img-product"
                  />
                </div>
                <p className="font-medium text-xl lg:text-base mt-2">{t("Produk Kepiting Lumpur")}</p>
              </div>
            </div>
            <div className="text-[#4D4D4D] text-sm text-justify">
              <p>{t("Home Service Breeding and Cultivation Species Desc")}</p>
              <p className="mt-2">
                {t("Home Service Breeding and Cultivation Species Desc 2")}
              </p>
            </div>
          </div>
        </div>
      </section>
    </GuestLayout>
  );
};

export default Aboutus;
