import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../languageselector";

const GuestLayout = ({ children, inArticle, inHome }) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const [isScrollDown, setIsScrollDown] = useState(false);

  const handleScroll = () => {
    const top = window.pageYOffset;
    setIsScrollDown(top >= 480 ? true : false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isScrollDown]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const { t } = useTranslation();

  const isActive = (pathname) => {
    return location.pathname === pathname;
  };

  const menus = [
    { title: t("Navbar Beranda"), link: "/" },
    { title: t("Navbar Tentang Kami"), link: "/about-us" },
    { title: t("Navbar Artikel"), link: "/article-list" },
    { title: t("Navbar Kontak Kami"), link: "/contact-us" },
  ];

  const services = [
    { title: t("Home Service Farming Methods") },
    { title: t("Home Service Industry Chain Extension") },
    { title: t("Home Service Breeding and Cultivation Species") },
  ];

  const contacts = [
    { title: "fuyuaquaticinternational@gmail.com", link: "mailto:fuyuaquaticinternational@gmail.com" },
    { title: "+6281266331175", link: "https://wa.me/+6281266331175" },
  ];

  return (
    <>
      <nav
        className={`z-20 fixed lg:flex lg:justify-around md:justify-around p-2 w-full items-center bg-white bg-opacity-40 backdrop-blur-sm shadow-md`}
      >
        <div className="flex font-semibold items-center">
          {inHome ?
            <div
              className="text-hitam-fuyu font-fuyu flex items-center"
            >
              <img
                src={`${inArticle
                  ? "../assets/images/logo-fuyu.png"
                  : "assets/images/logo-fuyu.png"
                  }`}
                alt="logo-perusahaan"
                className={`float-left mr-1 w-16 transition-all ease-in-out ${isScrollDown === true ? 'opacity-100' : 'opacity-0'}`}
              />
              <div className={`flex items-center transition-all ease-in-out ${isScrollDown === true ? 'opacity-100' : 'opacity-0'}`}>
                <h1
                  className="font-normal text-[9px] mr-2"
                  style={{ writingMode: "vertical-lr" }}
                >
                  富渔国际
                </h1>
                <div>
                  <h1 className="font-bold text-[12px]">FUYU</h1>
                  <h2 className="font-normal text-[9px]">
                    Aquatic International
                  </h2>
                </div>
              </div>
            </div>
            :
            <div
              className="text-hitam-fuyu font-fuyu flex items-center"
            >
              <img
                src={`${inArticle
                  ? "../assets/images/logo-fuyu.png"
                  : "assets/images/logo-fuyu.png"
                  }`}
                alt="logo-perusahaan"
                className="float-left mr-1 w-16 transition-all ease-in-out"
              />
              <div className="flex items-center transition-all ease-in-out">
                <h1
                  className="font-normal text-[9px] mr-2"
                  style={{ writingMode: "vertical-lr" }}
                >
                  富渔国际
                </h1>
                <div>
                  <h1 className="font-bold text-[12px]">FUYU</h1>
                  <h2 className="font-normal text-[9px]">
                    Aquatic International
                  </h2>
                </div>
              </div>
            </div>
          }

          {/* Mobile Menu Button */}
          <div className=" lg:hidden absolute right-0 mx-16">
            <LanguageSelector />
          </div>
          <div
            className=" lg:hidden absolute right-0 mx-5 hover:bg-black rounded-lg transition ease-in-out duration-300"
            onClick={toggleMobileMenu}
          >
            <IoMenu className="w-10 h-10 hover:text-white" />
          </div>

          {/* Mobile & tab Menu */}
          {isMobileMenuOpen && (
            <div className="lg:hidden absolute top-full left-0 w-full bg-white z-20">
              <Link
                to={`/${location.search}`}
                className={`block py-2 text-center hover:font-bold hover:-translate-y-0.5 duration-300 ${location.pathname === "/" ? "bg-gray-200" : ""
                  }`}
                onClick={closeMobileMenu}
              >
                {t("Navbar Beranda")}
              </Link>
              <Link
                to={`/about-us${location.search}`}
                className={`block py-2 text-center hover:font-bold hover:-translate-y-0.5 duration-300 ${location.pathname === "/about-us" ? "bg-gray-200" : ""
                  }`}
                onClick={closeMobileMenu}
              >
                {t("Navbar Tentang Kami")}
              </Link>
              <Link
                to={`/article-list${location.search}`}
                className={`block py-2 text-center hover:font-bold hover:-translate-y-0.5 duration-300 ${location.pathname === "/article-list" ? "bg-gray-200" : ""
                  }`}
                onClick={closeMobileMenu}
              >
                {t("Navbar Artikel")}
              </Link>
              <Link
                to={`/contact-us${location.search}`}
                className={`block py-2 text-center hover:font-bold hover:-translate-y-0.5 duration-300 ${location.pathname === "/contact-us" ? "bg-gray-200" : ""
                  }`}
                onClick={closeMobileMenu}
              >
                {t("Navbar Kontak Kami")}
              </Link>
            </div>
          )}
        </div>

        <div className="flex items-center justify-between w-1/2">
          <Link
            to={`/${location.search}`}
            className={`hidden lg:block mt-2.5 hover:transition ease-in-out text-hitam-fuyu ${isActive("/") ? "font-bold" : ""
              } hover:-translate-y-0.5 duration-300 text-lg`}
          >
            {t("Navbar Beranda")}
          </Link>
          <Link
            to={`/about-us${location.search}`}
            className={`hidden lg:block mt-2.5 hover:transition ease-in-out text-hitam-fuyu ${isActive("/about-us") ? "font-bold" : ""
              } hover:-translate-y-0.5 duration-300 text-lg`}
          >
            {t("Navbar Tentang Kami")}
          </Link>
          <Link
            to={`/article-list${location.search}`}
            className={`hidden lg:block mt-2.5 hover:transition ease-in-out text-hitam-fuyu ${isActive("/article-list") ? "font-bold" : ""
              } hover:-translate-y-0.5 duration-300 text-lg`}
          >
            {t("Navbar Artikel")}
          </Link>
          <Link
            to={`/contact-us${location.search}`}
            className={`hidden lg:block mt-2.5 hover:transition ease-in-out text-hitam-fuyu ${isActive("/contact-us") ? "font-bold" : ""
              } hover:-translate-y-0.5 duration-300 text-lg`}
          >
            {t("Navbar Kontak Kami")}
          </Link>
        </div>
        <div className="hidden lg:block">
          <LanguageSelector />
        </div>
      </nav>

      <div>{children}</div>

      <div className="static bottom-0 bg-primary-fuyu w-full py-5 px-5">
        <div className="container mx-auto w-full lg:mb-10 ">
          <div className="grid 2xl:grid-cols-4 lg:grid-cols-2 mx-3 md:mx-10 sm:mx-8 lg:mx-12">
            <div className="lg:mr-8 mt-3 flex flex-col items-center text-center">
              <img
                src={`${inArticle
                  ? "../assets/images/logo-white.svg"
                  : "assets/images/logo-white.svg"
                  }`}
                alt="logo-perusahaan"
                width="50"
                height="50"
              />
              <div>
                <h1 className="mx-auto font-semibold text-white text-sm pt-4">
                  FUYU Aquatic International
                </h1>
                <p className="text-sm text-white">富渔国际</p>
              </div>
              <div className="text-white flex gap-4 items-center justify-center mt-4">
              </div>
            </div>

            <div className="mt-6 text-white md:ml-2 xl:ml-8  2xl:ml-16">
              <h1 className="text-sm font-semibold">
                {t("Footer Laman Kami")}
              </h1>
              <ul className="space-y-2 mt-2">
                {menus.map((menu, i) => (
                  <li key={i}>
                    <Link to={menu.link + location.search} className="hover:underline">
                      {menu.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mt-6 text-white md:ml-2 2xl:ml-16">
              <h1 className="text-sm font-semibold">{t("Layanan Kami")}</h1>
              <ul className="space-y-2 mt-2">
                {services.map((service, i) => (
                  <li key={i}>{service.title}</li>
                ))}
              </ul>
            </div>

            <div className="mt-6 text-white md:ml-2 xl:ml-8 2xl:ml-16">
              <h1 className="text-sm font-semibold">
                {t("Navbar Kontak Kami")}
              </h1>
              <ul className="space-y-2 mt-2">
                {contacts.map((contact, i) => (
                  <li key={i}>
                    <Link to={contact.link} target="_blank" className="hover:underline">
                      {contact.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuestLayout;
