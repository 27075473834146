import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FaSave } from "react-icons/fa";
import { ref, set, onValue } from "firebase/database";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { FIREBASE_DB, FIREBASE_STORAGE } from "../config/firebaseinit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditArticle = () => {
  const [editorContent, setEditorContent] = useState("");
  const [editorContentChina, setEditorContentChina] = useState("");
  const [editorContentEng, setEditorContentEng] = useState("");
  const [articleData, setArticleData] = useState({
    judul: "",
    judulChina: "",
    judulEng: "",
    deskripsi: "",
    deskripsiChina: "",
    deskripsiEng: "",
    kategori: "", // Set a default category value
    konten: "",
    kontenChina: "",
    kontenEng: "",
    tanggal: "",
    gambar: "",
  });
  const [categories, setCategories] = useState([]);

  const navigate = useNavigate();
  const { id: articleId } = useParams();

  // Add this line inside the useEffect where you fetch article data
  useEffect(() => {
    const articleRef = ref(FIREBASE_DB, `artikel/${articleId}`);
    console.log("Article Path:", articleRef.toString());

    onValue(articleRef, (snapshot) => {
      if (snapshot.exists()) {
        setArticleData(snapshot.val());
        setEditorContent(snapshot.val().konten);
        setEditorContentChina(snapshot.val().kontenChina);
        setEditorContentEng(snapshot.val().kontenEng);

        // Log the image URL
        console.log("Image URL:", snapshot.val().gambar);
      } else {
        console.log("No data available");
      }
    });
  }, [articleId]);

  useEffect(() => {
    // Fetch categories from wherever they are stored (replace this with your actual data source)
    const fetchedCategories = ["Perikanan"];
    setCategories(fetchedCategories);
  }, []);

  const handleSave = async () => {
    const articleRef = ref(FIREBASE_DB, `artikel/${articleId}`);

    // Update data
    const updatedArticleData = {
      judul: document.getElementById("judulArtikel").value,
      judulChina: document.getElementById("judulArtikel-china").value,
      judulEng: document.getElementById("judulArtikel-eng").value,
      deskripsi: document.getElementById("descArt").value,
      deskripsiChina: document.getElementById("descArt-china").value,
      deskripsiEng: document.getElementById("descArt-eng").value,
      kategori: document.getElementById("kategori").value,
      konten: editorContent,
      kontenChina: editorContentChina,
      kontenEng: editorContentEng,
      tanggal: articleData.tanggal,
    };

    // Update image only if a new image is selected
    const imageInput = document.getElementById("art-image");
    const imageFile = imageInput.files[0];

    if (imageFile) {
      const storageRefVar = storageRef(
        FIREBASE_STORAGE,
        `images/${imageFile.name}`
      );

      try {
        // Upload new image
        await uploadBytes(storageRefVar, imageFile);
        console.log("New image uploaded to Firebase Storage");

        // Get the new image URL
        const imageUrl = await getDownloadURL(storageRefVar);
        updatedArticleData.gambar = imageUrl;
      } catch (error) {
        console.error("Error uploading image:", error.message);
      }
    } else {
      // No new image selected, keep the existing image URL
      updatedArticleData.gambar = articleData.gambar;
    }

    try {
      // Update the article data in Firebase
      await set(articleRef, updatedArticleData);
      console.log("Article updated successfully!");
      toast.success("Article updated successfully");
      setTimeout(() => {
        navigate("/admin"); // Navigate to the admin page
      }, 2000);
    } catch (error) {
      console.error("Error updating article:", error.message);
    }
  };

  return (
    <section className="absolute overflow-x-hidden w-full h-full scroll-auto z-30">
      <div className="absolute w-full h-full scroll-auto z-30">
        <div className="flex items-center justify-between shadow-xl">
          <div className="py-5 lg:pl-12 flex items-center">
            <img
              src="../assets/images/logo-fuyu.png"
              className="w-14 h-14"
              alt="logo"
            ></img>
            <div>
              <h1 className="font-semibold text-lg">
                FUYU Aquatic International
              </h1>
            </div>
          </div>
          <div>
            <Link to="/admin">
              <button
                className="p-5 absolute right-20 top-6 w-[130px] h-[30px] rounded-lg transition ease-in-out delay-80 hover:-translate-y-1 
              hover:scale-105 duration-100 border-2"
              >
                <IoArrowBack className="float-left w-7 h-7 -mt-3.5 -ml-1" />
                <p className="text-center text-base -mt-3">Back</p>
              </button>
            </Link>
          </div>
        </div>
        <div className="h-full rounded-t-[50px]">
          <div className="p-16">
            <h1 className="lg:text-4xl md:text-2xl sm:text-xl font-semibold text-lg">
              Edit Article
            </h1>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <div>
              <form
                className="pt-10"
              >
                <div className="lg:text-xl md:text-base sm:text-sm font-semibold grid grid-cols-2 md:grid-cols-12 gap-4 items-center">
                  <div className="col-span-2">Judul</div>
                  <input
                    type="text"
                    required
                    placeholder="Ketik judul artikel..."
                    id="judulArtikel"
                    className="w-full col-span-2 md:col-span-10 border p-2 rounded-md focus:outline-none placeholder:text-sm placeholder:font-normal font-normal text-sm"
                    value={articleData.judul}
                    onChange={(e) =>
                      setArticleData({
                        ...articleData,
                        judul: e.target.value,
                      })
                    }
                  />
                  <div className="col-span-2">Judul Bahasa China</div>
                  <input
                    type="text"
                    required
                    placeholder="Ketik judul artikel..."
                    id="judulArtikel-china"
                    className="w-full col-span-2 md:col-span-10 border p-2 rounded-md focus:outline-none placeholder:text-sm placeholder:font-normal font-normal text-sm"
                    value={articleData.judulChina}
                    onChange={(e) =>
                      setArticleData({
                        ...articleData,
                        judulChina: e.target.value,
                      })
                    }
                  />
                  <div className="col-span-2">Judul Bahasa Inggris</div>
                  <input
                    type="text"
                    required
                    placeholder="Ketik judul artikel..."
                    id="judulArtikel-eng"
                    className="w-full col-span-2 md:col-span-10 border p-2 rounded-md focus:outline-none placeholder:text-sm placeholder:font-normal font-normal text-sm"
                    value={articleData.judulEng}
                    onChange={(e) =>
                      setArticleData({
                        ...articleData,
                        judulEng: e.target.value,
                      })
                    }
                  />
                  <div className="col-span-2">Deskripsi</div>
                  <textarea
                    type="text"
                    placeholder="Ketik deskripsi singkat.."
                    required
                    name="descArt"
                    id="descArt"
                    className="w-full col-span-2 md:col-span-10 border p-2 rounded-md focus:outline-none placeholder:text-sm placeholder:font-normal font-normal text-sm"
                    value={articleData.deskripsi}
                    onChange={(e) =>
                      setArticleData({
                        ...articleData,
                        deskripsi: e.target.value,
                      })
                    }
                  />
                  <div className="col-span-2">Deskripsi Bahasa China</div>
                  <textarea
                    type="text"
                    placeholder="Ketik deskripsi singkat.."
                    required
                    name="descArt-china"
                    id="descArt-china"
                    className="w-full col-span-2 md:col-span-10 border p-2 rounded-md focus:outline-none placeholder:text-sm placeholder:font-normal font-normal text-sm"
                    value={articleData.deskripsiChina}
                    onChange={(e) =>
                      setArticleData({
                        ...articleData,
                        deskripsiChina: e.target.value,
                      })
                    }
                  />
                  <div className="col-span-2">Deskripsi Bahasa Inggris</div>
                  <textarea
                    type="text"
                    placeholder="Ketik deskripsi singkat.."
                    required
                    name="descArt-eng"
                    id="descArt-eng"
                    className="w-full col-span-2 md:col-span-10 border p-2 rounded-md focus:outline-none placeholder:text-sm placeholder:font-normal font-normal text-sm"
                    value={articleData.deskripsiEng}
                    onChange={(e) =>
                      setArticleData({
                        ...articleData,
                        deskripsiEng: e.target.value,
                      })
                    }
                  />
                  <div className="col-span-2 -mt-16">Kategori</div>
                  <select
                    required
                    id="kategori"
                    value={articleData.kategori}
                    onChange={(e) =>
                      setArticleData({
                        ...articleData,
                        kategori: e.target.value,
                      })
                    }
                    className="w-full -mt-16 col-span-2 md:col-span-4 border p-2 rounded-md focus:outline-none placeholder:text-sm placeholder:font-normal font-normal text-sm"
                  >
                    <option value="" disabled>
                      Pilih kategori...
                    </option>
                    {categories.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>

                  <div className="col-span-2 md:text-center -mt-16">Gambar</div>
                  <div className="text-base font-normal -mt-16">
                    <input
                      required
                      name="artImage"
                      id="art-image"
                      type="file"
                      className="pt-20"
                    />
                    {/* Display the current image if available */}
                    {articleData.gambar !== "" && (
                      <div className="flex">
                        <img
                          src={articleData.gambar}
                          alt="current_article_image"
                          className="w-32 h-16 mt-2 mr-3"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap mt-3">
                  <div className="lg:text-xl md:text-base sm:text-sm font-semibold mt-5 w-2/12">
                    Konten Artikel
                  </div>
                  <div className="mt-3 w-10/12">
                    <CKEditor
                      editor={ClassicEditor}
                      data={editorContent}
                      onChange={(event, editor) => {
                        const content = editor.getData();
                        setEditorContent(content);
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap mt-3">
                  <div className="lg:text-xl md:text-base sm:text-sm font-semibold mt-5 w-2/12">
                    Konten Artikel Bahasa China
                  </div>
                  <div className="mt-3 w-10/12">
                    <CKEditor
                      editor={ClassicEditor}
                      data={editorContentChina}
                      onChange={(event, editor) => {
                        const content = editor.getData();
                        setEditorContentChina(content);
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap mt-3">
                  <div className="lg:text-xl md:text-base sm:text-sm font-semibold mt-5 w-2/12">
                    Konten Artikel Bahasa Inggris
                  </div>
                  <div className="mt-3 w-10/12">
                    <CKEditor
                      editor={ClassicEditor}
                      data={editorContentEng}
                      onChange={(event, editor) => {
                        const content = editor.getData();
                        setEditorContentEng(content);
                      }}
                    />
                  </div>
                </div>
                <div className="text-right pt-6">
                  <button
                    type="button"
                    onClick={handleSave}
                    className="text-white py-3 px-5 bg-blue-700 hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center me-2"
                  >
                    <FaSave className="float-left w-6 h-6 -ml-2 mr-2" />
                    Simpan
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditArticle;
