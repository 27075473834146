import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

function LanguageSelector() {
  const { i18n } = useTranslation();
  const [queryParameters, setQueryParameters] = useSearchParams();
  const newQueryParameters = new URLSearchParams();
  const [language, setLanguage] = useState();

  useEffect(() => {
    const getQuery = queryParameters.get("lang");
    // setQueryParams(language)
    setLanguage(getQuery ?? language);
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setQueryParams(lng);
    setLanguage(lng);
  };

  const setQueryParams = (lang) => {
    if (lang) {
      newQueryParameters.set("lang", lang);
      setQueryParameters(newQueryParameters);
    }
  };

  return (
    <div>
      <div className="flex flex-row sm:mr-10 items-center right-1 border rounded-md ">
        <button
          onClick={() => changeLanguage("en")}
          className={`p-2 flex flex-row items-center text-sm font-medium hover:bg-gray-100 group
           hover:text-hitam-fuyu focus:bg-gray-200 focus:outline-none hover:rounded-l-md focus:rounded-l-md ${
             language === "en" ? "bg-gray-200" : ""
           }`}
        >
          <span
            className={`text-md hidden sm:block md:block lg:block xl:block  group-hover:text-hitam-fuyu text-hitam-fuyu`}
          >
            EN
          </span>
          <span className="ml-1">
            {" "}
            <img
              src="https://img.icons8.com/?size=512&id=t3NE3BsOAQwq&format=png"
              alt="en"
              className="w-5 h-5"
            />
          </span>
        </button>

        <button
          onClick={() => changeLanguage("id")}
          className={`p-2 flex flex-row items-center border-l group
          text-sm font-medium hover:text-hitam-fuyu hover:bg-gray-100 focus:bg-gray-200 focus:outline-none ${
            language === "id" ? "bg-gray-200" : ""
          }`}
        >
          <span
            className={`text-md hidden sm:block md:block lg:block xl:block  group-hover:text-hitam-fuyu text-hitam-fuyu`}
          >
            ID
          </span>
          <span className="ml-1">
            {" "}
            <img
              src="https://img.icons8.com/color/48/indonesia-circular.png"
              alt="id"
              className="w-5 h-5"
            />
          </span>
        </button>

        <button
          onClick={() => changeLanguage("cn")}
          className={`p-2 flex flex-row items-center border-l group
          text-sm font-medium hover:text-hitam-fuyu hover:bg-gray-100 focus:bg-gray-200 focus:rounded-r-md focus:outline-none hover:rounded-r-md ${
            language === "cn" ? "bg-gray-200" : ""
          }`}
        >
          <span
            className={`text-md hidden sm:block md:block lg:block xl:block  group-hover:text-hitam-fuyu text-hitam-fuyu`}
          >
            CN
          </span>
          <span className="ml-1">
            {" "}
            <img
              src="https://img.icons8.com/color/48/china-circular.png"
              alt="cn"
              className="w-5 h-5"
            />
          </span>
        </button>
      </div>
    </div>
  );
}

export default LanguageSelector;
