import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../components/header";
import { useTranslation } from "react-i18next";
import { IoIosSearch } from "react-icons/io";
import { TiDelete } from "react-icons/ti";
import GuestLayout from "../components/layouts/GuestLayout";
import { onValue, ref } from "firebase/database";
import { FIREBASE_DB } from "../config/firebaseinit";
import { Link, useLocation, useSearchParams } from "react-router-dom";

const Card = ({ img, title, desc, link }) => {
  return (
    <div
      className="border rounded-xl w-full lg:max-w-[405px] transition ease-in-out delay-80 hover:-translate-y-1 hover:scale-105 duration-300 flex flex-col justify-between"
    >
      <img
        src={img}
        className={`w-full max-h-[270px] rounded-t-xl`}
        alt="img"
      />
      <div className="p-8 pb-20">
        <p className="font-semibold text-2xl mb-5">{title}.</p>
        <p className="line-clamp-3">{desc}</p>
      </div>
      <div className="flex justify-end">
        <Link to={`/article-detail/${link}`} className="bg-[#C9C9C9] p-3 rounded-xl cursor-pointer mb-4 mr-4">
          <svg class="w-3.5 h-3.5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
          </svg>
        </Link>
      </div>
    </div>
  );
};

const ArticleList = () => {
  const { t } = useTranslation();
  const [dataArt, setDataArt] = useState([]);
  const [keyArticle, setKeyArticle] = useState([]);
  const [queryText, setQueryText] = useState("");
  const [filteredKey, setFilteredKey] = useState([]);
  const [queryParameters, setQueryParameters] = useSearchParams();
  const [tagKey, setTagKey] = useState("all");
  const getQuery = queryParameters.get("lang");
  const location = useLocation();
  const options = [
    {
      label: t("Artikel General"),
      link: "all",
    },
    {
      label: t("Artikel Perikanan"),
      link: "Perikanan",
    },
  ];

  useEffect(() => {
    AOS.init({
      /* konfigurasi AOS */
    });
  }, []);

  useEffect(() => {
    const articlesRef = ref(FIREBASE_DB, "artikel");
    onValue(articlesRef, (snapshot) => {
      const data = snapshot.val();
      const key = Object.keys(data);
      setKeyArticle(key);
      setDataArt(data);
      setFilteredKey(key);
    });
  }, [queryParameters]);

  const handleQuery = (e) => {
    const query = e.currentTarget.value;
    setQueryText(query);
    const res = keyArticle.filter((key) => {
      return dataArt[key].judul.toLowerCase().includes(query.toLowerCase());
    });
    setFilteredKey(res);
  };

  const handleTag = (kategori) => {
    setTagKey(kategori);
    if (kategori.toLowerCase() === "all") {
      setFilteredKey(keyArticle);
    } else {
      const res = keyArticle.filter((key) => {
        return dataArt[key].kategori
          .toLowerCase()
          .includes(kategori.toLowerCase());
      });
      setFilteredKey(res);
    }
  };

  return (
    <GuestLayout>
      <section>
        <Header title={t("Artikel Judul")} subtitle={t("Artikel Sub Judul")} />

        <div className="container flex justify-center lg:px-16 md:px-12 px-8 mx-auto lg:py-16 md:py-16 py-8">
          <div className="flex items-center justify-between text-[#9C9494] rounded-md border-2 border-primary-fuyu w-full md:w-[510px] lg:w-[753px]">
            <div className="flex items-center justify-between w-full px-5 py-3">
              <input
                type="text"
                placeholder={t("Artikel Cari")}
                value={queryText}
                onChange={(e) => handleQuery(e)}
                className="text-[13px] md:text-[16px] sm:text-[15px] md:text-xs lg:text-[18px] focus:outline-none w-full"
              />
            </div>
            <TiDelete
              className="lg:h-6 lg:w-6 mr-3"
              onClick={() => setQueryText("")}
            />
            <div className="text-white bg-primary-fuyu h-full flex items-center justify-between px-6">
              <IoIosSearch className="lg:h-6 lg:w-6" />
            </div>
          </div>
        </div>

        <div className="container lg:px-16 md:px-12 px-8 mx-auto mb-20">
          <div className="flex items-center gap-3 lg:justify-center lg:gap-16 overflow-x-auto">
            {options.map((it, i) => (
              <div
                key={i}
                className={`w-32 ${tagKey === it.link
                  ? "bg-primary-fuyu text-white"
                  : "bg-white text-hitam-fuyu"
                  } py-2 rounded-md text-center cursor-pointer`}
                onClick={() => handleTag(it.link)}
              >
                {it.label}
              </div>
            ))}
          </div>
        </div>

        <div className="container lg:px-16 md:px-12 px-8 mx-auto mb-36">
          <div
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7"
            data-aos="fade-up"
          >
            {filteredKey.length > 0
              ? filteredKey?.map((key) => (
                <Card
                  img={dataArt[key]?.gambar}
                  title={getQuery === "en" ? dataArt[key]?.judulEng : getQuery === "id" ? dataArt[key]?.judul : dataArt[key]?.judulChina}
                  desc={getQuery === "en" ? dataArt[key]?.deskripsiEng : getQuery === "id" ? dataArt[key]?.deskripsi : dataArt[key]?.deskripsiChina}
                  link={key + location.search}
                />
              ))
              : null}
          </div>
        </div>
      </section>
    </GuestLayout>
  );
};

export default ArticleList;
