import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Aboutus, Home } from "./pages";
import Contactus from "./pages/Contactus";
import Article from "./pages/Article";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import EditArticle from "./pages/EditArticle";
import NotFound from "./pages/NotFound";
import AddArticle from "./pages/AddArticle";
import { FirebaseProvider } from "./config/FirebaseContext";
import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./components/scrolltotop";
import ArticleList from "./pages/ArticleList";

const App = () => {
  return (
    <div>
      <FirebaseProvider className="App ">
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<Aboutus />} />
            <Route path="/article-list" element={<ArticleList />} />
            <Route path="/contact-us" element={<Contactus />} />
            <Route path="/article-detail/:id" element={<Article />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
            <Route element={<PrivateRoute />}>
              <Route path="/add-article" element={<AddArticle />}></Route>
              <Route path="/admin" element={<Admin />}></Route>
              <Route path="/edit-article/:id" element={<EditArticle />}></Route>
            </Route>
          </Routes>
        </Router>
      </FirebaseProvider>
    </div>
  );
};

export default App;
