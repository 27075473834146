import React from "react";

const Header = (props) => {

    return (
        <div className="bg-gradient-to-r from-biru-muda-fuyu to-biru-fuyu w-full h-[25rem] flex justify-center items-center">
            <div className="text-white text-center" data-aos="zoom-in">
                <h1 className="font-bold text-3xl md:text-[2.50rem]">{props.title}</h1>
                <h2 className="font-light text-2xl md:text-3xl my-2">{props.subtitle}</h2>
                {props.withBrand ? <span className="text-2xl md:text-3xl">富渔国际</span> : null}
            </div>
        </div>
    );
};

export default Header;
