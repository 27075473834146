import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import Header from "../components/header";
import GuestLayout from "../components/layouts/GuestLayout";
import { Link } from "react-router-dom";

const Contactus = () => {
  const [clipboardVal, setClipboardVal] = useState(null)

  useEffect(() => {
    AOS.init({
      /* konfigurasi AOS */
    });
  }, []);

  const { t } = useTranslation();

  const copyOperation = () => {
    navigator.clipboard.writeText('081266331175')
      .then(() => setClipboardVal('081266331175'))
      .catch((e) => {
        setClipboardVal(null)
        console.warn('Clipboard error:' + e)
      })
  }
  return (
    <GuestLayout>
      <section>
        <Header
          title={t("Navbar Kontak Kami")}
          subtitle={t('Kontak Judul')}
        />

        <div className="my-10 md:my-20">
          <div className="flex justify-center mx-5 md:mx-0">
            <h3 className="mb-20 text-center text-lg" data-aos="zoom-in">
              {t("Kontak Terhubung")}{" "}
              <span className="font-bold text-primary-fuyu">
                {t("Kontak Sosmed")}
              </span>{" "}
              {t("Kontak Sosmed1")}
            </h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-10">
            <div className="justify-self-center" data-aos="fade-right">
              <h2 className="font-bold text-primary-fuyu text-5xl text-center md:text-start">
                {t("Navbar Kontak Kami")}
              </h2>
              <div className="mt-10 flex items-center gap-x-3">
                <div className="hover:scale-110 duration-200 bg-gray-200 px-4 py-[9px] rounded-lg">
                  <div
                    className="flex items-center gap-x-3"
                  >
                    <Link to={'https://wa.me/+6281266331175'} className="flex items-center gap-x-2" target="_blank">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" id="telephone" className="w-6 h-6"><path d="M415.9 335.5c-14.6-15-56.1-43.1-83.3-43.1-6.3 0-11.8 1.4-16.3 4.3-13.3 8.5-23.9 15.1-29 15.1-2.8 0-5.8-2.5-12.4-8.2l-1.1-1c-18.3-15.9-22.2-20-29.3-27.4l-1.8-1.9c-1.3-1.3-2.4-2.5-3.5-3.6-6.2-6.4-10.7-11-26.6-29l-.7-.8c-7.6-8.6-12.6-14.2-12.9-18.3-.3-4 3.2-10.5 12.1-22.6 10.8-14.6 11.2-32.6 1.3-53.5-7.9-16.5-20.8-32.3-32.2-46.2l-1-1.2c-9.8-12-21.2-18-33.9-18-14.1 0-25.8 7.6-32 11.6-.5.3-1 .7-1.5 1-13.9 8.8-24 20.9-27.8 33.2-5.7 18.5-9.5 42.5 17.8 92.4 23.6 43.2 45 72.2 79 107.1 32 32.8 46.2 43.4 78 66.4 35.4 25.6 69.4 40.3 93.2 40.3 22.1 0 39.5 0 64.3-29.9 26-31.4 15.2-50.6-.4-66.7z" fill="#000000" className="color000000 svgShape"></path></svg>
                      <span className="font-medium text-gray-600">
                        +6281266331175
                      </span>
                    </Link>
                    <button data-tooltip-target="tooltip-default" type="button" onClick={copyOperation}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="Copy" className="w-4 h-4"><path fill="none" d="M0 0h48v48H0z"></path><path d="M32 2H8C5.79 2 4 3.79 4 6v28h4V6h24V2zm6 8H16c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h22c2.21 0 4-1.79 4-4V14c0-2.21-1.79-4-4-4zm0 32H16V14h22v28z" fill="#000000" className="color000000 svgShape"></path></svg>
                    </button>
                  </div>
                </div>
                <div className="hover:scale-110 duration-200 bg-gray-200 px-4 py-[9px] rounded-lg hover:cursor-pointer">
                  <Link
                    to={"mailto:fuyuaquaticinternational@gmail.com"}
                    target="_blank"
                    className="flex items-center gap-x-3"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                    >
                      <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z" />
                    </svg>
                    <span className="font-medium">{t("Tautan ke email")}</span>
                  </Link>
                </div>
              </div>
            </div>
            <div data-aos="fade-left">
              <img
                src="assets/illustrations/contact-us.svg"
                className="w-[40rem]"
                alt="contact-us.svg"
              />
            </div>
          </div>
        </div>
      </section>
    </GuestLayout>
  );
};

export default Contactus;
