import React, { useState, useEffect } from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import { ref, onValue } from "firebase/database";
import { FIREBASE_DB } from "../config/firebaseinit";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GuestLayout from "../components/layouts/GuestLayout";
import YouTube from "react-youtube";

const Home = () => {
  const [queryParameters, setQueryParameters] = useSearchParams();
  const [state, setState] = useState({
    openAccordion: false,
    selectedAccordion: null,
    latestArticles: [],
    isScrollDown: 320,
    clipboardVal: null,
  });
  const location = useLocation();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const articlesRef = ref(FIREBASE_DB, "artikel");
    onValue(articlesRef, (snapshot) => {
      if (snapshot.exists()) {
        const getQuery = queryParameters.get("lang");
        const articlesArray = Object.entries(snapshot.val()).map(
          ([id, data]) => ({
            id,
            judul:
              getQuery === "en"
                ? data.judulEng
                : getQuery === "id"
                  ? data.judul
                  : data.judulChina,
            gambar: data.gambar,
            // tanggal: formatTanggal(data.tanggal),
            deskripsi:
              getQuery === "en"
                ? data.deskripsiEng
                : getQuery === "id"
                  ? data.deskripsi
                  : data.deskripsiChina,
            konten:
              getQuery === "en"
                ? data.kontenEng
                : getQuery === "id"
                  ? data.konten
                  : data.kontenChina,
            kategori: data.kategori,
          })
        );

        const sortedArticles = articlesArray.sort(
          (a, b) => new Date(b.tanggal) - new Date(a.tanggal)
        );

        // Update the component state with the latest three articles
        const latestThreeArticles = sortedArticles.slice(1, 4);
        console.log(latestThreeArticles);
        // setLatestArticles(latestThreeArticles);
        setState((prevState) => ({
          ...prevState,
          latestArticles: latestThreeArticles,
        }));
      } else {
        console.log("No articles available");
      }
    });
  }, [queryParameters]);

  const { t } = useTranslation();

  const handleScroll = () => {
    const top = window.pageYOffset;
    setState((prev) => ({
      ...prev,
      isScrollDown: top >= 0 ? 320 - top / 2.5 : 320,
    }));
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [state.isScrollDown]);

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1,
      mute: 1,
    },
  };

  const copyOperation = () => {
    navigator.clipboard.writeText('081266331175')
      .then(() =>
        setState((prevState) => ({
          ...prevState,
          clipboardVal: '081266331175',
        })))
      .catch((e) => {
        setState((prevState) => ({
          ...prevState,
          clipboardVal: null,
        }))
        console.warn('Clipboard error:' + e)
      })
  }

  const faqData = [
    {
      title: t("FAQ heading A"),
      description: [t("FAQ deskripsi A1"), t("FAQ deskripsi A2")],
    },
    {
      title: t("FAQ heading B"),
      description: [t("FAQ deskripsi B1"), t("FAQ deskripsi B2")],
    },
    {
      title: t("FAQ heading C"),
      description: [t("FAQ deskripsi C1"), t("FAQ deskripsi C2")],
    },
    {
      title: t("FAQ heading D"),
      description: [t("FAQ deskripsi D1"), t("FAQ deskripsi D2")],
    },
    {
      title: t("FAQ heading E"),
      description: [t("FAQ deskripsi E1"), t("FAQ deskripsi E2")],
    },
    {
      title: t("FAQ heading F"),
      description: [t("FAQ deskripsi F1"), t("FAQ deskripsi F2")],
    },
  ];

  const servicesData = [
    {
      src: "assets/images/farming-methode.png",
      label: t("Home Service Farming Methods"),
      description: t("Home Service Farming Methods Desc"),
    },
    {
      src: "assets/icons/breeding.svg",
      label: t("Home Service Industry Chain Extension"),
      description: t("Home Service Industry Chain Extension Desc"),
    },
    {
      src: "assets/icons/supply-chain-management.svg",
      label: t("Home Service Breeding and Cultivation Species"),
      description: (
        <div>
          <p>{t("Home Service Breeding and Cultivation Species Desc")}</p>
          <p className="mt-2">
            {t("Home Service Breeding and Cultivation Species Desc 2")}
          </p>
        </div>
      ),
    },
  ];

  return (
    <GuestLayout inHome>
      <section>
        <div className="relative w-full h-[20rem] lg:h-screen">
          <img
            src="assets/images/img-cover.jpg"
            className="w-full h-full object-cover hidden md:block"
            alt=""
          />
          <div className="absolute bottom-0 left-0 bg-gradient-to-r from-biru-muda-fuyu md:from-55% to-biru-fuyu md:to-transparent w-full h-full flex items-center justify-center md:justify-normal">
            <div
              className="text-white font-fuyu lg:flex items-center space-x-4 md:ml-20 hidden"
              data-aos="zoom-in"
            >
              <img
                src="assets/images/logo-fuyu.png"
                // className={`w-[${state.isScrollDown}px] md:h-[${state.isScrollDown} px]`}
                style={{
                  width: state.isScrollDown + "px",
                  height: state.isScrollDown + "px",
                }}
                alt=""
              />
              <h1
                className="lg:text-3xl md:text-2xl font-normal lg:mt-2 sm:text-lg text-base"
                style={{ writingMode: "vertical-lr" }}
              >
                富渔国际
              </h1>
              <div>
                <h1 className="lg:text-5xl md:text-3xl font-bold sm:text-4xl text-lg">
                  FUYU
                </h1>
                <h2 className="lg:text-3xl md:text-2xl font-normal lg:mt-2 sm:text-lg text-base">
                  Aquatic International
                </h2>
              </div>
            </div>

            <div
              className="text-white font-fuyu flex items-center space-x-4 md:ml-20 lg:hidden"
              data-aos="zoom-in"
            >
              <img
                src="assets/images/logo-fuyu.png"
                className={`w-32 h-32`}
                alt=""
              />
              <h1
                className="lg:text-3xl md:text-2xl font-normal lg:mt-2 sm:text-lg text-base"
                style={{ writingMode: "vertical-lr" }}
              >
                富渔国际
              </h1>
              <div>
                <h1 className="lg:text-5xl md:text-3xl font-bold sm:text-4xl text-lg">
                  FUYU
                </h1>
                <h2 className="lg:text-3xl md:text-2xl font-normal lg:mt-2 sm:text-lg text-base">
                  Aquatic International
                </h2>
              </div>
            </div>
          </div>
        </div>

        {/* pengenalan perusahaan */}
        <div className="lg:flex lg:space-x-5 text-justify mx-10 my-20">
          <p data-aos="zoom-in" className="font-fuyu">
            {t("Home Deskripsi")}
          </p>
          <p data-aos="zoom-in" className="font-fuyu mt-5 lg:mt-0">
            {t("Home Deskripsi 2")}
          </p>
        </div>

        {/* video kami */}
        <div className="bg-primary-fuyu lg:px-16 px-8 md:py-10 py-8">
          <div className="xl:flex xl:justify-between">
            <div className="w-full">
              <div className="text-white text-center xl:text-left">
                <h1
                  data-aos="zoom-in"
                  className="text-xl font-semibold font-fuyu sm:text-2xl md:text-3xl lg:text-5xl mb-3 lg:mb-5"
                >
                  {t("Home Video Kami")}
                </h1>
                <span
                  data-aos="zoom-in"
                  className="text-base font-normal font-fuyu sm:text-lg md:text-xl lg:text-xl lg:text-left"
                >
                  {t("Home Video Singkat Kami")}
                </span>
              </div>
            </div>
            <div className="flex justify-center mt-5 xl:mt-0">
              <div className="w-full h-[10rem] sm:h-[25rem] md:w-[40rem] md:h-[25rem] lg:w-[45rem] lg:h-[390px]">
                <YouTube videoId="NqjM1kyA3m8" opts={opts} style={{ height: "100%", width: "100%" }} />
              </div>
            </div>
          </div>
        </div>

        {/* layanan */}
        <div className="lg:mx-16 md:mx-12 mx-8 lg:mt-16 md:mt-16 mt-8">
          <p
            data-aos="zoom-in"
            className="text-left lg:text-center mx-auto font-fuyu font-semibold text-5xl text-primary-fuyu"
          >
            {t("Layanan Kami")}
          </p>
          <div
            className="flex flex-wrap w-full gap-8 items-start justify-center mt-10"
            data-aos="zoom-in"
          >
            {servicesData.map((it, i) => (
              <div
                key={i}
                className={`w-full flex flex-col lg:flex-row gap-4 p-10 items-center bg-blue-service rounded-md ${i === 2 ? "w-[1083px] min-h-[175px]" : "lg:w-[638px] lg:h-[175px]"
                  }`}
              >
                <img
                  src={it.src}
                  className="w-16 h-16 lg:object-cover lg:object-center"
                  alt=""
                />
                <div className="flex flex-col items-stretch justify-start">
                  <p className="text-primary-fuyu text-2xl font-semibold mb-3">
                    {it.label}
                  </p>
                  <p className="text-[#4D4D4D] text-sm text-justify">
                    {it.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* artikel */}
        <div className="lg:mx-12 md:mx-12 mx-8 lg:mt-16 md:mt-16 mt-12">
          <p
            data-aos="zoom-in"
            className="text-left lg:text-center mx-auto font-fuyu font-semibold text-5xl text-primary-fuyu"
          >
            {t("Home Artikel Terbaru")}
          </p>
          <div
            className="grid lg:grid-cols-2 xl:grid-cols-3 gap-8 mt-10"
            data-aos="zoom-in"
          >
            {state.latestArticles.map((article, index) => (
              <div
                key={index}
                className="border rounded-xl transition ease-in-out delay-80 hover:-translate-y-1 hover:scale-105 duration-300 flex flex-col justify-between"
              >
                <img
                  src={article.gambar}
                  className="w-full h-[270px] object-cover object-center rounded-t-xl"
                  alt={`Article ${index + 1}`}
                />
                <div className="px-8 pt-8">
                  <p className="font-semibold text-2xl mb-5">
                    {article.judul.length > 250
                      ? `${article.judul.substring(0, 250)}...`
                      : article.judul}
                  </p>
                  <p>
                    {article.deskripsi.length > 200
                      ? `${article.deskripsi.substring(0, 200)}...`
                      : article.deskripsi}
                  </p>
                </div>
                <div className="flex justify-end">
                  <Link
                    to={`/article-detail/${article.id + location.search}`}
                    className="bg-[#C9C9C9] p-3 rounded-xl cursor-pointer mb-4 mr-4"
                  >
                    <svg
                      className="w-3.5 h-3.5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            ))}
            <Link />
          </div>
        </div>

        {/* peta lokasi */}
        <div className="lg:mx-12 md:mx-12 mx-8 lg:mt-24 md:mt-16 mt-12">
          <p
            data-aos="zoom-in"
            className="block lg:hidden text-left font-fuyu font-semibold text-5xl text-primary-fuyu"
          >
            {t("Home Map")}
          </p>
          <div
            className="flex flex-col-reverse lg:flex-row justify-between items-center"
            data-aos="zoom-in"
          >
            <div>
              <p className="hidden lg:block text-left font-fuyu font-semibold text-2xl sm:text-2xl md:text-6xl lg:text-6xl text-primary-fuyu">
                {t("Home Map")}
              </p>
              <div className="flex flex-col gap-3 mt-10">
                <p className="text-hitam-fuyu font-semibold text-xl">
                  {t("Address")} :
                </p>
                <p className="">
                  Jl. JAKSA AGUNG R. SOEPRAPTO NO. 5, Desa/Kelurahan Rimbo
                  Kaluang, Kec. Padang Barat, Kota Padang, Provinsi Sumatera
                  Barat, Kode Pos : 2511
                </p>
                <p className="text-hitam-fuyu font-semibold text-xl">
                  {t("Gmaps")} :
                </p>
                <a
                  href="https://maps.app.goo.gl/SgdcctWoCTcoqx5w8"
                  className="text-primary-fuyu underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://maps.app.goo.gl/SgdcctWoCTcoqx5w8
                </a>
              </div>
            </div>
            <img
              src="assets/illustrations/home-contact-us.svg"
              className="w-full lg:w-[40rem] hidden lg:block"
              alt=""
            />
          </div>
        </div>

        {/* Pertanyaan yang Sering Diajukan */}
        <div className="lg:mx-12 md:mx-12 mx-8 lg:mt-24 md:mt-16 mt-12">
          <p className="block lg:hidden font-fuyu font-semibold text-5xl text-primary-fuyu">
            {t("FAQ")}
          </p>
          <div
            className="flex flex-col lg:flex-row lg:justify-between lg:gap-20 items-start"
            data-aos="zoom-in"
          >
            <div className="flex justify-center w-full">
              <img
                src="assets/illustrations/frequently-asked-questions.svg"
                className="w-[35rem]"
                alt=""
              />
            </div>
            <div className="w-full">
              <p className="hidden lg:block font-fuyu font-semibold text-2xl sm:text-2xl md:text-6xl lg:text-6xl text-primary-fuyu mt-12">
                {t("FAQ")}
              </p>
              {faqData.map((it, i) => {
                return (
                  <div
                    key={i}
                    className={`flex flex-col gap-3 pt-4 border-b-2 transition ${i === 0 ? "mt-10 border-t-2" : ""
                      }`}
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        selectedAccordion:
                          prevState.selectedAccordion === i + 1 ? null : i + 1,
                      }));
                    }}
                  >
                    <div className="flex justify-between flex-wrap items-start">
                      <p className="text-hitam-fuyu w-11/12 text-xl xl:text-lg font-semibold">{it.title}</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        id="Close"
                        className={`w-6 h-6 ${state.selectedAccordion === i + 1
                          ? "rotate-0"
                          : "rotate-45"
                          } transition-all transform p-1 hover:bg-primary-fuyu hover:bg-opacity-5 rounded cursor-pointer`}
                      >
                        <path
                          d="M437.5 386.6L306.9 256l130.6-130.6c14.1-14.1 14.1-36.8 0-50.9-14.1-14.1-36.8-14.1-50.9 0L256 205.1 125.4 74.5c-14.1-14.1-36.8-14.1-50.9 0-14.1 14.1-14.1 36.8 0 50.9L205.1 256 74.5 386.6c-14.1 14.1-14.1 36.8 0 50.9 14.1 14.1 36.8 14.1 50.9 0L256 306.9l130.6 130.6c14.1 14.1 36.8 14.1 50.9 0 14-14.1 14-36.9 0-50.9z"
                          fill="#08339f"
                          className="color000000 svgShape"
                        ></path>
                      </svg>
                    </div>
                    <div className={`${state.selectedAccordion === i + 1 && ('mb-4')} transform duration-500 ease-in-out`}>
                      {it.description.map((desc, index) => (
                        <div
                          key={index}
                          className={`text-[#3c3c4370] px-6 pt-0 overflow-hidden transition-[max-height] duration-500 ease-in-out ${state.selectedAccordion === i + 1
                            ? "max-h-40"
                            : "max-h-0"
                            }`}
                        >
                          <p>{`${index + 1}. ${desc}`}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* Kontak */}
        <div className="flex items-center lg:justify-around my-5 lg:mx-12 md:mx-12 mx-8 lg:mt-24 md:mt-16 mt-12 mb-20">
          <div data-aos="fade-right">
            <h2 className="font-bold text-primary-fuyu text-5xl text-center sm:text-start">
              {t("Navbar Kontak Kami")}
            </h2>
            <div className="mt-10 flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 sm:space-x-3">
              <div className="hover:scale-110 duration-200 bg-gray-200 px-4 py-[9px] rounded-lg">
                <div
                  className="flex items-center gap-x-3"
                >
                  <Link to={'https://wa.me/+6281266331175'} className="flex items-center gap-x-2" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" id="telephone" className="w-6 h-6"><path d="M415.9 335.5c-14.6-15-56.1-43.1-83.3-43.1-6.3 0-11.8 1.4-16.3 4.3-13.3 8.5-23.9 15.1-29 15.1-2.8 0-5.8-2.5-12.4-8.2l-1.1-1c-18.3-15.9-22.2-20-29.3-27.4l-1.8-1.9c-1.3-1.3-2.4-2.5-3.5-3.6-6.2-6.4-10.7-11-26.6-29l-.7-.8c-7.6-8.6-12.6-14.2-12.9-18.3-.3-4 3.2-10.5 12.1-22.6 10.8-14.6 11.2-32.6 1.3-53.5-7.9-16.5-20.8-32.3-32.2-46.2l-1-1.2c-9.8-12-21.2-18-33.9-18-14.1 0-25.8 7.6-32 11.6-.5.3-1 .7-1.5 1-13.9 8.8-24 20.9-27.8 33.2-5.7 18.5-9.5 42.5 17.8 92.4 23.6 43.2 45 72.2 79 107.1 32 32.8 46.2 43.4 78 66.4 35.4 25.6 69.4 40.3 93.2 40.3 22.1 0 39.5 0 64.3-29.9 26-31.4 15.2-50.6-.4-66.7z" fill="#000000" className="color000000 svgShape"></path></svg>
                    <span className="font-medium text-gray-600">
                      +6281266331175
                    </span>
                  </Link>
                  <button data-tooltip-target="tooltip-default" type="button" onClick={copyOperation}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="Copy" className="w-4 h-4"><path fill="none" d="M0 0h48v48H0z"></path><path d="M32 2H8C5.79 2 4 3.79 4 6v28h4V6h24V2zm6 8H16c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h22c2.21 0 4-1.79 4-4V14c0-2.21-1.79-4-4-4zm0 32H16V14h22v28z" fill="#000000" className="color000000 svgShape"></path></svg>
                  </button>
                </div>
              </div>
              <div className="hover:scale-110 duration-200 bg-gray-200 px-4 py-[9px] rounded-lg hover:cursor-pointer w-full">
                <Link
                  to={"mailto:fuyuaquaticinternational@gmail.com"}
                  target="_blank"
                  className="flex items-center gap-x-3"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z" />
                  </svg>
                  <span className="font-medium">{t("Tautan ke email")}</span>
                </Link>
              </div>
            </div>
          </div>
          <div data-aos="fade-left">
            <img
              src="assets/illustrations/contact-us.svg"
              className="w-[25rem] lg:w-[30rem] hidden lg:block"
              alt="contact-us.svg"
            />
          </div>
        </div>
      </section>
    </GuestLayout>
  );
};

export default Home;
