import React from "react";
import { useState, useEffect } from "react";
import { ref, onValue } from "firebase/database";
import { FIREBASE_DB } from "../config/firebaseinit";
import { MdDateRange } from "react-icons/md";
import { useLocation, useParams, useSearchParams } from "react-router-dom"; // Import useParams
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import GuestLayout from "../components/layouts/GuestLayout";
import { formatTanggal } from "../utils";

const Article = () => {
  useEffect(() => {
    AOS.init({
      /* konfigurasi AOS */
    });
  }, []);

  const { t } = useTranslation();
  const { id } = useParams();
  const [currentArticleID, setCurrentArticleID] = useState(id);
  const [queryParameters, setQueryParameters] = useSearchParams();
  const [articleData, setArticleData] = useState({
    judul: "",
    gambar: "",
    tanggal: "",
    deskripsi: "",
    konten: "",
    kategori: "",
  });
  const location = useLocation();
  const [recentArticles, setRecentArticles] = useState([]);

  useEffect(() => {
    const articleRef = ref(FIREBASE_DB, `artikel/${id}`);
    const getQuery = queryParameters.get("lang");
    onValue(articleRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setCurrentArticleID(id);
        setArticleData({
          judul:
            getQuery === "en"
              ? data.judulEng
              : getQuery === "id"
                ? data.judul
                : data.judulChina,
          gambar: data.gambar,
          tanggal: formatTanggal(data.tanggal),
          deskripsi:
            getQuery === "en"
              ? data.deskripsiEng
              : getQuery === "id"
                ? data.deskripsi
                : data.deskripsiChina,
          konten:
            getQuery === "en"
              ? data.kontenEng
              : getQuery === "id"
                ? data.konten
                : data.kontenChina,
          kategori: data.kategori,
        });
      } else {
        console.log("Artikel tidak ditemukan");
      }
    });

    return () => {
      setSearchTerm("");
    };
  }, [id, queryParameters]);

  useEffect(() => {
    const articlesRef = ref(FIREBASE_DB, "artikel");
    const getQuery = queryParameters.get("lang");
    onValue(articlesRef, (snapshot) => {
      if (snapshot.exists()) {
        const articlesData = snapshot.val();
        const articlesArray = Object.entries(articlesData).map(
          ([key, value]) => ({ ...value, id: key })
        );
        const filteredArticles = articlesArray.filter(
          (article) => article.id !== id
        );
        filteredArticles.sort(
          (a, b) => new Date(b.tanggal) - new Date(a.tanggal)
        );
        const latestArticles = filteredArticles.slice(0, 4);
        const mergeLatestArticles = latestArticles.map((data) => {
          return {
            id: data.id,
            judul:
              getQuery === "en"
                ? data.judulEng
                : getQuery === "id"
                  ? data.judul
                  : data.judulChina,
            gambar: data.gambar,
            tanggal: formatTanggal(data.tanggal),
          };
        });
        setRecentArticles(mergeLatestArticles);
      }
    });
  }, [id, queryParameters]);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  // Fungsi pencarian
  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    // Lakukan pencarian pada data artikel yang ada
    const results = recentArticles.filter((article) => {
      const getQuery = queryParameters.get("lang");
      return getQuery === "en"
        ? article.judulEng.toLowerCase().includes(term)
        : getQuery === "id"
          ? article.judul.toLowerCase().includes(term)
          : article.judulChina.toLowerCase().includes(term);
    });

    // Update hasil pencarian
    setSearchResults(results);
  };

  return (
    <GuestLayout inArticle>
      <section>
        <div className="relative w-full h-full md:h-96">
          <img
            src={articleData.gambar}
            className="w-full h-full object-cover"
            alt=""
          />
          <div className="absolute bottom-0 left-0 md:bg-gradient-to-b md:from-transparent md:to-gray-800 w-full md:h-2/5 bg-gray-800 md:bg-transparent bg-opacity-50 md:bg-opacity-100 h-full flex items-end justify-center md:justify-normal">
            <h1 className="text-xl md:text-4xl font-semibold text-white mb-5 md:ml-10 text-center md:text-start mx-5 md:mx-0">
              {articleData.judul}
            </h1>
          </div>
        </div>

        <div className="xl:flex xl:justify-center my-10 mx-3 lg:mx-10 xl:mx-0">
          <div className="grid md:grid-cols-3 xl:w-4/5 gap-y-4 lg:gap-y-0 max-h-fit">
            <div className="md:col-span-2 md:border-r-2 md:pr-2">
              <div className="flex items-center justify-between mb-7">
                <span className="bg-biru-fuyu px-4 py-1 rounded-md text-white font-medium">
                  #{articleData.kategori === "Perikanan" ? t("Artikel Perikanan") : ""}
                </span>
                <div className="flex items-center space-x-2">
                  <MdDateRange />
                  <span>{articleData.tanggal}</span>
                </div>
              </div>
              <div dangerouslySetInnerHTML={{ __html: articleData.konten }} />
            </div>
            <div className="md:ml-2">
              <div data-aos="fade-up" className="relative w-full mb-5">
                <input
                  type="text"
                  placeholder={t("Detail Cari")}
                  className="w-full border p-2 rounded-2xl focus:outline-none"
                  value={searchTerm}
                  onChange={handleSearch}
                />

                {/* Menampilkan dropdown hasil pencarian */}
                {searchTerm !== "" && searchResults.length > 0 && (
                  <div className="absolute bg-white w-full mt-2 border rounded-md shadow-md">
                    {searchResults.map((result, index) => (
                      <Link
                        key={index}
                        to={`/article-detail/${result.id + location.search}`}
                        className="block p-2 hover:bg-gray-200"
                      >
                        {result.judul}
                      </Link>
                    ))}
                  </div>
                )}
              </div>

              <div className="grid grid-rows-3 gap-y-3">
                {recentArticles.map((article, index) => (
                  <div key={index}>
                    <div className="bg-white mb-2 h-[250px] w-full lg:w-full lg:h-[280px] xl:h-[300px]  2xl:h-[320px] rounded-lg overflow-hidden text-black shadow-md">
                      <img
                        src={article.gambar}
                        alt={article.judul}
                        className="2xl:h-[200px] xl:h-[180px] h-[130px] w-full object-cover object-center"
                      />
                      <div className="grid grid-rows-1 px-3">
                        <h1 className="text-base hidden xl:block text-justify font-medium mt-2">
                          {article.judul.length > 45
                            ? `${article.judul.substring(0, 45)}...`
                            : article.judul}
                        </h1>
                        <h1 className="text-base hidden lg:block xl:hidden text-justify font-medium mt-2">
                          {article.judul.length > 40
                            ? `${article.judul.substring(0, 40)}...`
                            : article.judul}
                        </h1>
                        <h1 className="text-base lg:hidden text-justify font-medium mt-2">
                          {article.judul.length > 30
                            ? `${article.judul.substring(0, 30)}...`
                            : article.judul}
                        </h1>
                      </div>
                      <div className="grid grid-cols-3 px-3 py-2 text-sm">
                        <div className="col-span-2 flex items-center">
                          <MdDateRange className="mr-1 xl:h-5 xl:w-5" />
                          <p>{formatTanggal(article.tanggal)}</p>
                        </div>
                        <Link
                          to={`/article-detail/${article.id + location.search}`}
                          className="block"
                        >
                          <div className="col-span-1 text-right">
                            <button
                              type="button"
                              className="text-white px-4 py-1 font-medium rounded-md bg-primary-fuyu hover:bg-blue-200 focus:outline-none"
                            >
                              {t("Button Read")}
                            </button>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </GuestLayout>
  );
};

export default Article;
