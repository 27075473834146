import React, { useState } from "react";
import { useFirebase } from "../config/FirebaseContext";
import { Navigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { authMethods, user, errorMessage } = useFirebase();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await authMethods.signIn(email, password);
  };

  if (user) {
    return <Navigate to="/admin" />;
  }

  return (
    <section className="fixed overflow-x-hidden z-30 w-full h-screen">
      <div className="hidden lg:block">
        <div className="flex flex-wrap">
          <div className="w-5/12 h-screen">
            <img
              className="h-screen w-full object-cover grayscale-[50%]"
              src="assets/images/jumbotron.jpg"
              alt="jumbotron"
            />
          </div>
          <div className="container flex w-7/12 h-screen">
            <div className="my-auto">
              <div className="px-32">
                <h1 className="text-5xl font-semibold text-blue-900">
                  FUYU Aquatic International
                </h1>
                <h1 className="text-4xl font-semibold text-black mt-3">
                  Login Admin
                </h1>
                <p className="text-xs text-red-500 mt-2">
                  {errorMessage.message}
                </p>
              </div>
              <div className="mt-5 px-32 text-black">
                <form onSubmit={handleSubmit}>
                  <label for="email">
                    <span className="block font-medium text-lg mb-1 after:content-['*'] after:text-pink-700 after:ml-0.5">
                      Email Address
                    </span>
                    <input
                      id="email"
                      type="email"
                      placeholder="Masukkan Email"
                      onChange={(e) => setEmail(e.target.value)}
                      className=" px-3 py-2 border shadow rounded w-full block text-base focus:outline-none focus:ring-1
                     focus:ring-sky-500  focus:border-sky-500 invalid:text-red-700 invalid:focus:ring-red-700 invalid:focus:border-red-700"
                    />
                  </label>
                  <label for="password">
                    <span className="block font-medium text-lg mb-1 after:content-['*'] after:text-pink-700 after:ml-0.5 mt-3">
                      Password
                    </span>
                    <input
                      id="password"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Masukkan Password"
                      className=" px-3 py-2 border shadow rounded w-full block text-base focus:outline-none focus:ring-1
                     focus:ring-sky-500  focus:border-sky-500"
                    />
                  </label>
                  <div className="end-0 right-0 mt-5">
                    <button
                      type="submit"
                      className="p-5 bg-primary-fuyu text-white w-[100px] h-[30px] rounded-lg"
                    >
                      <p className="text-center text-base -mt-3">Login</p>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:hidden relative">
        <div className="grayscale-[50%] brightness-75">
          <img
            src="assets/images/jumbotron.jpg"
            className="w-full h-screen object-cover object-center"
            alt="jumbotron"
          />
        </div>

        <div className="flex justify-center">
          <div className="absolute bg-white opacity-90 rounded-xl top-28 w-[90%] md:w-3/5 px-5 py-5 mx-5 md:mx-0">
            <img
              src="assets/images/logo-fuyu.png"
              className="mx-auto w-32"
              alt="logo-fuyu.png"
            />
            <h1 className="text-center bg-center text-xl font-medium">
              FUYU Aquatic International
            </h1>
            <h2 className="text-center font-medium text-lg ">Login Admin</h2>
            <div className="mt-5">
              <form action="">
                <label for="email">
                  <span className="block font-medium text-base mb-1 after:content-['*'] after:text-pink-700 after:ml-0.5">
                    Email Address
                  </span>
                  <input
                    id="email"
                    type="email"
                    placeholder="Masukkan Email"
                    className=" px-3 py-2 border shadow rounded w-full block text-sm focus:outline-none focus:ring-1
                     focus:ring-sky-500  focus:border-sky-500 invalid:text-red-700 invalid:focus:ring-red-700 invalid:focus:border-red-700"
                  />
                </label>
                <label for="password">
                  <span className="block font-medium text-base mb-1 after:content-['*'] after:text-pink-700 after:ml-0.5 mt-3">
                    Password
                  </span>
                  <input
                    id="password"
                    type="password"
                    placeholder="Masukkan Password"
                    className=" px-3 py-2 border shadow rounded w-full block text-sm focus:outline-none focus:ring-1
                     focus:ring-sky-500  focus:border-sky-500"
                  />
                </label>
                <div className="end-0 right-0 mt-5">
                  <button
                    type="submit"
                    className="p-5 bg-primary-fuyu text-white w-[100px] h-[30px] rounded-lg"
                  >
                    <p className="text-center text-base -mt-3">Login</p>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
