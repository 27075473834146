import React from "react";
import { useLocation } from "react-router-dom";

const NotFound = () => {
  const location = useLocation();

  return (
    <div className="fixed w-full h-screen">
      <div className="lg:flex lg:grid-cols-2">
        <div className="w-auto h-auto mt-24 lg:h-auto lg:w-[460px] lg:ml-[300px] lg:mt-44 lg:items-start flex flex-col items-center">
          <p className="text-[25px] md:text-[45px] md:h-[35px] sm:text-[45px] sm:h-[30px] h-[15px] lg:text-[56px] lg:h-[40px] font-medium text-[#021E5D]">
            ERROR
          </p>
          <p className="text-[70px] md:text-[130px] md:h-[160px] sm:text-[100px] sm:h-[120px] h-[85px] lg:text-[150px] lg:h-[190px] font-bold text-[#861B25]">
            404
          </p>
          <p className="text-[20px] md:text-[30px] sm:text-[30px] lg:text-[40px] font-medium text-[#021E5D]">
            PAGE NOT FOUND
          </p>
          <a href={`/${location.search}`} className="px-4 py-0.5 bg-primary-fuyu text-white rounded-full mt-5 text-lg">
            Back Home
          </a>
        </div>
        <div className="flex items-center justify-center">
          <img
            src="assets/images/notfound.png"
            className="w-auto h-[250px] lg:h-auto lg:w-[540px] lg:mr-[130px] lg:ml-[100px] lg:mt-[120px] md:h-[330px]"
            alt="Not_Found_Image"
          />
        </div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        className="absolute bottom-0"
        style={{ zIndex: -1 }}
      >
        <path
          fill="#021E5D"
          fill-opacity="1"
          d="M0,128L1440,32L1440,320L0,320Z"
        ></path>
      </svg>
    </div>
  );
};

export default NotFound;
