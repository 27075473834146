// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage"
import { getDatabase } from "firebase/database"

const firebaseConfig = {
  apiKey: "AIzaSyDR4pq85HjdGEM5h0VLeyvJ-17F69fKNSE",
  authDomain: "fuyu-aqua.firebaseapp.com",
  databaseURL: "https://fuyu-aqua-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "fuyu-aqua",
  storageBucket: "fuyu-aqua.appspot.com",
  messagingSenderId: "251692890500",
  appId: "1:251692890500:web:419c6037de177954dc05d1",
  measurementId: "G-24MVMEZFQ9"
};

// Initialize Firebase
export const FIREBASE_APP = initializeApp(firebaseConfig);
export const FIREBASE_AUTH = getAuth(FIREBASE_APP);
export const FIREBASE_STORE = getStorage(FIREBASE_APP);
export const FIREBASE_DB = getDatabase(FIREBASE_APP);
export const FIREBASE_STORAGE = getStorage(FIREBASE_APP);