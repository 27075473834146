import React, { useState, useEffect } from "react";
import { useFirebase } from "../config/FirebaseContext";
import { Link, useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { ref, get, remove } from "firebase/database";
import { FIREBASE_DB } from "../config/firebaseinit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Admin = () => {
  const [error, setError] = useState("");
  const [articles, setArticles] = useState([]); // State untuk menyimpan data artikel
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const { authMethods } = useFirebase();

  const handleLogout = async () => {
    setError("");

    try {
      await authMethods.signOut();
      navigate("/login");
    } catch {
      setError("Failed to Log Out");
    }
  };

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  const handleDelete = async (articleId) => {
    setError("");

    try {
      // Remove the article from Firebase
      await remove(ref(FIREBASE_DB, `artikel/${articleId}`));

      // Update the local state by filtering out the deleted article
      setArticles((prevArticles) =>
        prevArticles.filter((article) => article.id !== articleId)
      );

      // No need to navigate here, the table will update automatically
    } catch (error) {
      console.error("Error deleting article:", error.message);
      setError("Failed to delete article");
      toast.error("Error deleting article :" + error.message);
    }

    toast.success("Success deleting article");
  };

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        // Mengambil data dari Firebase
        const articlesSnapshot = await get(ref(FIREBASE_DB, "artikel"));
        const articlesData = [];

        articlesSnapshot.forEach((article) => {
          articlesData.push({ id: article.key, ...article.val() });
        });

        // Sort articles based on the date in descending order (latest first)
        const sortedArticles = articlesData.sort(
          (a, b) => new Date(b.tanggal) - new Date(a.tanggal)
        );

        // Filter articles based on the search query
        const filteredArticles = sortedArticles.filter((article) =>
          article.judul.toLowerCase().includes(searchQuery.toLowerCase())
        );

        // Menyimpan data artikel ke dalam state
        setArticles(filteredArticles);
      } catch (error) {
        console.error("Error fetching articles:", error.message);
      }
    };

    fetchArticles();
  }, [searchQuery]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = articles.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pages = Math.ceil(articles.length / itemsPerPage);

  const renderPageButtons = () => {
    const pageButtons = [];
    for (let i = 1; i <= pages; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => paginate(i)}
          className={`${currentPage === i ? "text-white" : "bg-white"
            } px-3 py-1 mx-1 rounded-md focus:outline-none`}
        >
          {i}
        </button>
      );
    }
    return pageButtons;
  };

  return (
    <section className="absolute overflow-x-hidden w-full h-screen z-30">
      <div className="absolute w-full h-screen z-30">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="grid grid-cols-2 shadow-xl">
          <div className=" py-5 lg:pl-12">
            <img
              src="assets/images/logo-fuyu.png"
              className="float-left w-14 h-14"
              alt="logo-fuyu.png"
            />
            <div>
              <h1 className="mt-3.5 font-semibold text-lg">
                FUYU Aquatic International
              </h1>
            </div>
          </div>
          <div className="">
            <button
              onClick={handleLogout}
              className="p-5 absolute right-20 top-6 w-[130px] h-[30px] rounded-lg transition ease-in-out delay-80 hover:-translate-y-1 
              hover:scale-105 duration-100 border-2 hover:hover:border-2"
            >
              <MdLogout className="float-left w-7 h-7 -mt-3.5 -ml-1" />
              <p className="text-center text-base -mt-3">Logout</p>
            </button>
          </div>
        </div>
        <div className="h-full rounded-t-[50px]">
          <div className="px-16 pt-16  pb-12">
            <div className="flex flex-col md:flex-row gap-4 items-center">
              <div className="md:w-fit w-full">
                <h1 className="lg:text-4xl md:text-2xl text-2xl  font-semibold text-center md:text-left">
                  Artikel
                </h1>
              </div>
              <div className="grow w-full md:w-auto">
                <input
                  type="text"
                  placeholder=" Cari judul artikel..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full border h-10 p-2 rounded-xl focus:outline-none placeholder:text-sm placeholder:font-normal font-normal text-sm"
                ></input>
                {/* <div className="ml-5 w-11/12 h-10 border-2 rounded-lg">
                <IoSearch className="float-left w-6 h-6 mt-1.5 ml-2 mr-2" />
                <input type="text" placeholder=" Cari judul artikel..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="w-11/12 py-2 h-8 focus:outline-none mt-0.5"></input>
              </div> */}
              </div>
              <div
                className="hover:transition md:w-fit w-full h-full rounded-xl
       ease-in-out font-semibold hover:-translate-y-0.5 duration-300 text-lg"
              >
                <Link to="/add-article">
                  <div className="flex items-center space-x-2">
                    <p>Tambah Artikel</p>
                    <FaPlus className="w-5 h-5" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="px-16">
            <div className="relative overflow-x-auto shadow-lg sm:rounded-lg border">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-200">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Judul
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Deskripsi
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Kategori
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Tanggal
                    </th>
                    <th scope="col" className="text-center">
                      Aksi
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((article) => (
                    <tr
                      key={article.id}
                      className="bg-white border-b hover:bg-gray-100"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900"
                      >
                        {article.judul}
                      </th>
                      <td className="px-6 py-4">{article.deskripsi}</td>
                      <td className="px-6 py-4">{article.kategori}</td>
                      <td className="px-6 py-4">
                        {formatDate(article.tanggal)}
                      </td>
                      <td className="px-6 py-4 text-right">
                        <div className="flex items-center space-x-2">
                          <Link to={`/edit-article/${article.id}`}>
                            <div
                              type="button"
                              className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center me-2"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 -960 960 960"
                                className="w-5 h-5 text-white"
                                fill="currentColor"
                              >
                                <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h357l-80 80H200v560h560v-278l80-80v358q0 33-23.5 56.5T760-120H200Zm280-360ZM360-360v-170l367-367q12-12 27-18t30-6q16 0 30.5 6t26.5 18l56 57q11 12 17 26.5t6 29.5q0 15-5.5 29.5T897-728L530-360H360Zm481-424-56-56 56 56ZM440-440h56l232-232-28-28-29-28-231 231v57Zm260-260-29-28 29 28 28 28-28-28Z" />
                              </svg>
                            </div>
                          </Link>
                          <button
                            type="button"
                            onClick={() => handleDelete(article.id)}
                            className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center me-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 -960 960 960"
                              className="w-5 h-5 text-white"
                              fill="currentColor"
                            >
                              <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
                            </svg>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="pb-4">
            <div className="flex justify-center mt-4">
              <nav>
                <ul className="flex items-center">{renderPageButtons()}</ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Admin;
